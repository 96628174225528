<template>
  <div class="modal fade" id="modalLogout" tabindex="-1" role="dialog" aria-labelledby="modalLogout" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalLogout"> 
              <fa v-if="$store.state.dark_mode == 'light'" :icon="['fas', 'right-from-bracket']" class="ml-4" style="color:black;"> </fa> 
              <fa v-else :icon="['fas', 'right-from-bracket']" class="ml-4" style="color:white;"> </fa> 
                ¿Desea cerrar sesión ahora? </h5>
            <button type="button" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close" class="btn-close"></button>
          </div>
          <div class="modal-body">
            <p class="modal-text">
              Para mayor seguridad, se recomienda cerrar este navegador despues de cerrar la sesión
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn" data-dismiss="modal" data-bs-dismiss="modal"><i class="flaticon-cancel-12"></i> No </button>
            <button type="button" class="btn btn-primary" data-dismiss="modal" data-bs-dismiss="modal" @click="logOut">Cerrar</button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  methods: {

    logOut(){
      store.dispatch('logOut_User');
      store.dispatch('setOnboardingBepay',0);

      store.dispatch('setApp', {});
      store.dispatch('logOut_ShopsUser');
      store.dispatch('logOut_Menu');
      store.dispatch('logOut_MenusRol');
      store.dispatch('setNotification', []);
      router.replace("/log_out");
    }
  }
}
</script>