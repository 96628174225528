// src/i18n.js
import { createI18n } from 'vue-i18n';

// Función para cargar mensajes de locales
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

// Crear instancia de i18n
const i18n = createI18n({
  allowComposition: true,
  // legacy: false, // Utiliza la nueva API en lugar de la API heredada
  locale: 'es',
  fallbackLocale: 'es',
  messages: loadLocaleMessages(),
});

export default i18n;


