<template>
  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-3">
    <div class="d-flex justify-content-between">
      <p class="">{{ $t(props.label) }}</p>
      <span data-bs-toggle="tooltip" style="font-size: 18px" :title="$t(props.title)"><fa :icon="['fas', 'circle-info']" /></span>
    </div>

    <div class="input-group mb-2">
      <span class="input-group-text" id="basic-addon2">
        <fa :icon="['fas', props.icon]" class="feather feather-bell"> </fa>
      </span>
      <input
        class="form-control"
        aria-label="notification"
        aria-describedby="basic-addon2"
        :disabled="props.disabled"
        :type="props.type"
        :placeholder="$t(props.placeholder)"
        v-model="controller"
        :required="props.required"
        @change="uploadEmit"
      />
    </div>
  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue';

  const controller = ref('');

  const props = defineProps({
    label: { default: 'name', type: String, required: false },
    title: { default: 'Reference text', type: String },
    icon: { default: 'hashtag', type: String },
    disabled: { default: false, type: Boolean },
    type: { default: 'text', type: String },
    placeholder: { default: ' ', type: String },
    controller: { type: String },
    required: { default: false, type: Boolean, required: false },
  });

  function uploadEmit() {
    emit('updateValue', controller.value);
  }

  const emit = defineEmits(['updateValue']);

  onMounted(() => {
    controller.value = props.controller;
  });
</script>
