import { ref } from 'vue';
import { useStore } from 'vuex';

export const formatoSaldo = new Intl.NumberFormat('es-CO', {
  style: 'currency',
  currency: 'COP',
  minimumFractionDigits: 0,
});
export function rutaPermitida(pathname) {
  const store = useStore();
  const menu = store.getters.getMenuStore;
  const submenu = store.getters.getSubMenuStore;

  if (menu.find((item) => item.urlMenu == pathname) || submenu.find((item) => item.urlMenu == pathname)) {
    return true;
  } else {
    router.push('/error401');
    return false;
  }
}
