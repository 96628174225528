const countryList = [
  { code: 'es', name: 'Spanish' },
  { code: 'en', name: 'English' },
  // { code: 'col', name: 'Spanish' },
];

const user = {
  email: '',
  id: '',
  idRol: '',
  idApp: '',
  idShop: '',
  name: '',
  nameShop: '',
  is_login: false,
  tokens: {
    access: '',
    refresh: '',
  },
  //   expiration:""
};

const shopsUser = [];

const menu = {
  id: '',
  nameMenu: '',
  urlMenu: '',
  icon: '',
  numOrder: '',
  idMenu: '',
};

const app = {
  id: 0,
  brandName: '',
  name: '',
  urlLogo: '',
  status: 0,
};

const menus = [];

const submenu = [];

const menusrol = [];

const notification = [];

const onBoardingBepay = 0;
const onBoardingBepayImage = '';

const state = {
  layout: 'app',
  is_show_sidebar: true,
  is_show_search: false,
  is_dark_mode: false,
  dark_mode: 'light',
  locale: null,
  menu_style: 'vertical',
  layout_style: 'full',
  countryList: countryList,
  app: app,
  user: user,
  shopsUser: shopsUser,
  menus: menus,
  submenu: submenu,
  menusrol: menusrol,
  notification: notification,
  onBoardingBepay: onBoardingBepay,
  onBoardingBepayImage: onBoardingBepayImage,
};

export default state;
