<template>
  <!-- LIGHT DARK MODE -->
  <div class="dark-mode d-flex align-items-center">
    <a v-if="$store.state.dark_mode == 'light'" href="javascript:;" class="d-flex align-items-center" @click="toggleMode('dark')">
      <fa :icon="['fas', 'sun']" class="feather feather-sun"> </fa> 
      <span class="ms-4">{{ $t('Light') }}</span>
    </a>
    <a v-if="$store.state.dark_mode == 'dark'" href="javascript:;" class="d-flex align-items-center" @click="toggleMode('light')">
      <fa :icon="['fas', 'moon']" class="feather feather-moon"> </fa> 
      <span class="ms-4">{{ $t('Dark') }}</span>
    </a>
  </div>
</template>

<script setup>
  import { onMounted } from 'vue';

  onMounted(() => {
    toggleMode();
  });

  const toggleMode = (mode) => {
    window.$appSetting.toggleMode(mode);
  };
</script>