<template>
  <ul class="navbar-item theme-brand flex-row text-center">
    <li class="nav-item theme-logo">
      <router-link to="/">
        <img v-if="user.idApp == 0 && user.idShop == 0 && user.idRol == 10" src="@/assets/images/Favicon.svg" id="logoImg" class="navbar-logo" alt="logo" style="background-color: white; border-radius: 15px !important;" />
        <img v-else-if="appData.urlLogo!=null" :src="appData.urlLogo" :key="appData.urlLogo" id="logoImg" class="navbar-logo" alt="logo" />

      </router-link>
    </li>
    <li class="nav-item theme-text">
      <router-link to="/" class="nav-link"> {{ appData.brandName }} </router-link>
    </li>
  </ul>
</template>

<style>
    #logoImg {
      width: auto !important;
      height: 45px !important;
      margin: 0px 5px;
      padding: 3px;
    }
</style>

<script setup>
  import { useStore } from 'vuex';
  import { onMounted, ref } from 'vue';

  const store = useStore();
  const user = store.getters.getUser;
  var appData = ref(store.getters.getApp);
  // Para implementar cambios en el header, ir al archivo _navbar.scss linea 22
  
  onMounted(() => { (async () => {
      await appLogin();
    })();
  });

  async function appLogin() {
  try {
    if (user.idApp == 0 && user.idShop == 0 && user.idRol == 10) {
      const app = {
        id: 0,
        brandName: 'SuperAdmin',
        name: 'Contento',
        urlLogo: "@/assets/images/Favicon.svg",
        status: 1,
      };

      store.dispatch('setApp', app);

    }
    else{ 
      const data = {
        id: user.idApp,
      };
  
      const res = await axiosWS.getAppbyId(data);
  
      if (res.status === 200) {
        if (res.data.error) {
          escribirMensaje(false, res.data.msg);
        } else {
          const { data } = res;
          const app = {
            id: data.app.id,
            brandName: data.app.brandName,
            name: data.app.name,
            urlLogo: data.app.urlLogo,
            status: data.app.status,
          };
  
          store.dispatch('setApp', app);
          appData.value = store.getters.getApp;
        }
      }
    }
  } catch (error) {
    console.error("Error al obtener la aplicación por ID:", error);
  }
}

</script>
