<template>
  <div  class="row layout-top-spacing">
    <h5 class="mt-4 mb-2"> Resumen administrativo </h5>
      
      <!-- Ventas Totales -->
      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing">
        
        <div class="widget widget-dailysales">

          <div class="widget-heading">
            <div>
              <h5> Ventas totales </h5>
            </div>
            <div class="w-icon text-warning">
              <svg
                xmlns="http://www.w3.org/2000/svg" width="24"             height="24"
                viewBox="0 0 24 24"                fill="none"            stroke="currentColor"
                stroke-width="2"                   stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-dollar-sign"
              >
                <line x1="12" y1="1" x2="12" y2="23"></line>
                <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
              </svg>
            </div>
          </div>

          <div :key="totalSales.total" v-if="totalSales.total" class="widget-content center-horizontal">
            <div style="display: flex; flex-direction: column;">
              <h6 class="mx-auto TotalT">
                {{ formatoSaldo.format(totalSales.total) }} COP
              </h6>
              <div class="flex-container mt-2 mb-2" style="display: flex;">
                <div style="height: 80px; flex-grow: 2; margin-top:auto; margin-bottom: auto; justify-content: flex-start;">
                  <p class="mx-auto SubtitleT" >
                    Transacciones
                  </p>
                </div>
                <div style="height: 80px; flex-grow: 1; margin-top:auto; margin-bottom: auto;  justify-content: flex-start;">
                  <p class="mx-auto Subtitle2T">
                    {{ totalSales.transactions }}
                  </p>
                </div>
                <div style="height: 80px; flex-grow: 1; margin-top:auto; margin-bottom: auto; ">
                  <img src="@/assets/images/contento/cart.png" style="height: 60px; margin-top:0px; margin-bottom: auto">
                </div>
              </div>
            </div>
          </div>



        </div>
      </div>
      
      <!-- Número de Locales Virtuales -->
      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing">
        <div class="widget widget-summary">
          <div class="widget-heading" style="padding-bottom: 0px;">
            <h5> Número de locales Virtuales </h5>
            <h6 class="mx-auto TotalT">
              {{ totalStores?.total }}
            </h6>
          </div>
          <div class="widget-content" style="padding-top: 5px;">
            <div class="summary-list">
              <div class="w-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-shopping-bag"
                >
                  <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                  <line x1="3" y1="6" x2="21" y2="6"></line>
                  <path d="M16 10a4 4 0 0 1-8 0"></path>
                </svg>
              </div>
              <div class="w-summary-details">
                <div class="w-summary-info">
                  <h6> {{totalStores?.totalStoresMP[0]?.category}}</h6>
                  <p class="summary-count">{{totalStores?.totalStoresMP[0]?.totalShopCategory}}</p>
                </div>
                <div class="w-summary-stats">
                  <div class="progress">
                    <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="90" class="progress-bar bg-gradient-secondary" style="width: 90%"></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="summary-list">
              <div class="w-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-credit-card"
                >
                  <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                  <line x1="1" y1="10" x2="23" y2="10"></line>
                </svg>
              </div>
              <div class="w-summary-details">
                <div class="w-summary-info">
                  <h6> {{totalStores?.totalStoresMP[1]?.category}}</h6>
                  <p class="summary-count">{{totalStores?.totalStoresMP[1]?.totalShopCategory}}</p>
                </div>
                <div class="w-summary-stats">
                  <div class="progress">
                    <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="80" class="progress-bar bg-gradient-warning" style="width: 80%"></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="summary-list">
              <div class="w-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="17"  height="17"
                  viewBox="0 0 24 24"                   fill="none" stroke="currentColor"
                  stroke-width="2"  stroke-linecap="round"          stroke-linejoin="round"
                  class="feather feather-tag"
                >
                  <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path>
                  <line x1="7" y1="7" x2="7" y2="7"></line>
                </svg>
              </div>
              <div class="w-summary-details">
                <div class="w-summary-info">
                  <h6> {{totalStores?.totalStoresMP[2]?.category}}</h6>
                  <p class="summary-count">{{totalStores?.totalStoresMP[2]?.totalShopCategory}}</p>
                </div>
                <div class="w-summary-stats">
                  <div class="progress">
                    <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="65" class="progress-bar bg-gradient-success" style="width: 65%"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- Ticket Promedio -->
      <div :key="LastTrs?.length>0" class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-transaction">
          <div class="widget-heading">
            <h5> Ticket Promedio </h5>
            <div class="task-action">
              <div>
                <div class="form-group">
                  <div class="input-group">
                    <select :required="true" :disabled="true" :searchable="true" v-model="category"  class="form-select w-100">
                      <option v-for="i in listCategories" :value="i.id" :key="i.id">
                        {{ i.type }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div :key="totalSales.total" v-if="totalSales.total" class="widget-content">
            <h6 class="mx-auto TotalT">
                {{ formatoSaldo.format(totalSales.total/totalSales.transactions) }} COP
            </h6> 
          </div>
        </div>
      </div>

      
      <!-- Productos mas vendidos -->
      <div :key="LastMostSales?.length>0" class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-top-selling-products">
          <div class="widget-heading">
            <h5> Top Ventas </h5>
          </div>

          <div class="widget-content table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th><div class="th-content">Comercio</div></th>
                  <th><div class="th-content th-heading">Nit</div></th>
                  <th><div class="th-content">Categoria</div></th>
                  <th><div class="th-content">Ventas</div></th>
                </tr>
              </thead>
              
              <tbody :key="mostSales?.length" v-if="mostSales?.length>0"  style="--bs-table-bg: transparent;" >
                <tr  v-for="sp in mostSales" :key="sp.id">
                  <td><div class="td-content"> {{ sp.nameShop }}          </div></td>
                  <td><div class="td-content"> {{ sp.nit?sp.nit:'000'}}</div></td>
                  <td><div class="td-content"> {{ sp.nameCategory }}      </div></td>
                  <td><div class="td-content"> {{ formatoSaldo.format(sp.sales) }}      </div></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- Comercios Recientes  -->
      <div :key="lastStores?.length>0" class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-recent-orders">
          <div class="widget-heading">
            <h5> Comercios Recientes </h5>
          </div>
          <div class="widget-content table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th><div class="th-content">Comercio</div></th>
                  <th><div class="th-content">Nit</div></th>
                  <th><div class="th-content th-heading">Categoria</div></th>
                </tr>
              </thead>
              <tbody :key="lastStores?.length" v-if="lastStores?.length>0" style="--bs-table-bg: transparent;" >
                <tr  v-for="store in lastStores" :key="store.id">
                  <td><div class="td-content"> {{ store.nameShop }}          </div></td>
                  <td><div class="td-content"> {{ store.nit?store.nit:'000'}}</div></td>
                  <td><div class="td-content"> {{ store.nameCategory }}      </div></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-sales-category">
          <div class="widget-heading">
            <h5> Visitas al Centro Comercial </h5>
          </div>
          <div class="widget-content">
            <apex-chart v-if="sales_donut_options" height="460" type="donut" :options="sales_donut_options" :series="sales_donut_series"></apex-chart>
          </div>
        </div>
      </div>
      
    </div>
</template>

<script setup>
  // Importacion de librerias
  import { useStore } from 'vuex';
  import { useMeta } from '@/composables/use-meta';
  import { onMounted, computed, ref } from 'vue';

  // Importacion de Alerts y components
  import ApexChart from 'vue3-apexcharts';
  import { formatoSaldo } from '@/constants/constants';

  // Librerias aparte de fontAwesome
  import '@/assets/sass/font-icons/fontawesome/css/fontawesome.css';
  import '@/assets/sass/font-icons/fontawesome/css/regular.css';
  // Librerias de Widgets
  import '@/assets/sass/widgets/widgets.scss';

  //Constantes del scrip
  const store            = useStore();
  const user             = store.getters.getUser;
  const localstore       = store.getters.getLocalStore;

  // Objects
  const totalSales       = ref({})
  const totalStores      = ref(
    { 
      total: 0, 
      totalStoresMP: [
        { category: "", totalShopCategory: 0 }, 
        { category: "", totalShopCategory: 0}, 
        { category: "", totalShopCategory: 0},
      ]
    }
  )
  const listCategories = ref([{id:1, type: " Todas "}])
  const category       = ref(0)
  const lastStores     = ref([])
  const mostSales      = ref([])


  const sales_donut_series = ref([0]);
  const sales_donut_options = computed(() => {
    const is_dark = store.state.is_dark_mode;
    const option = {
      chart: {},
      dataLabels: { enabled: false },
      expandOnClick: is_dark ? false : true,
      stroke: { show: true, width: 25, colors: is_dark ? '#0e1726' : '#fff' },
      colors: is_dark ? ['#5c1ac3', '#e2a03f', '#e7515a', '#e2a03f'] : ['#e2a03f', '#5c1ac3', '#e7515a'],
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        fontSize: '14px',
        markers: { width: 10, height: 10 },
        height: 50,
        offsetY: 20,
        itemMargin: { horizontal: 8, vertical: 0 },
      },
      plotOptions: {
        pie: {
          donut: {
            size: '65%',
            background: 'transparent',
            labels: {
              show: true,
              name: { show: true, fontSize: '29px', fontFamily: 'Nunito, sans-serif', offsetY: -10 },
              value: {
                show: true,
                fontSize: '26px',
                fontFamily: 'Nunito, sans-serif',
                color: is_dark ? '#bfc9d4' : undefined,
                offsetY: 16,
                formatter: function (val) {
                  return val;
                },
              },
              total: {
                show: true,
                label: 'Total',
                color: '#888ea8',
                fontSize: '29px',
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce(function (a, b) {
                    return a + b;
                  }, 0);
                },
              },
            },
          },
        },
      },
      labels: ['Visitantes'],
    };

    if (is_dark) {
      option['states'] = {
        hover: { filter: { type: 'none' } },
        active: { filter: { type: 'none' } },
      };
    }

    return option;
  });


  if (localstore == 'es') {
    useMeta({ title: 'Datos' });
  }else{
    useMeta({ title: 'Data' });
  }
  onMounted(() => {
    //ECO
    if(store.getters.getUser?.appType == 1) {
      // Ventas Totales
      GetTotalSalesMP()
      //  tiendas Totales 
      GetTotalStoressMP()

      // Comercios Recientes
      GetlastStores()

      // Mas vendidos
      GetMostSalesMP()
      // //Visitantes
      getVisitorsApp()
    }


  });

  // Función para obtener el total de ventas.
  async function GetTotalSalesMP() {
    const data = {
      bodyOptions: {
        idShop: +user.idShop,
        idApp: +user.idApp,
      }
    };

    try {
      const res = await axiosWS.GetTotalSalesMP(data);
      if (res.status === 200) {
        const { data } = res;
        if (data.error === false) {
          totalSales.value = data.report[0];
        }
      } else {
        throw new Error('Failed to fetch data for GetTotalSalesMP');
      }
    } catch (error) {
      console.error('Error fetching data for GetTotalSalesMP:', error.message);
    }
  }

  // Función para obtener el total de tiendas y tiendas por categoría.
  async function GetTotalStoressMP() {
    const data = {
      bodyOptions: {
        idShop: +user.idShop,
        idApp: +user.idApp,
      }
    };

    try {
      const res = await axiosWS.GetTotalStoresMP(data);
      if (res.status === 200) {
        const { data } = res;
        if (data.error === false) {
          totalStores.value.total = data.total; 
          totalStores.value.totalStoresMP = data.report;
          // Supongamos que totalStores.value.totalStoresMP es el objeto que necesitas reordenar
          let totalStoresMP = totalStores.value.totalStoresMP;

          // Usamos sort() para ordenar el objeto por totalShopCategory en orden descendente
          totalStoresMP.sort((a, b) => b?.totalShopCategory - a?.totalShopCategory); 

          totalStores.value.totalStoresMP = totalStoresMP

        }
      } else {
        throw new Error('Failed to fetch data for GetTotalStoressMP');
      }
    } catch (error) {
      console.error('Error fetching data for GetTotalStoressMP:', error.message);
    }
  }

  // Función para obtener las últimas tiendas.
  async function GetlastStores() {
    const data = {
      bodyOptions: {
        idShop: +user.idShop,
        idApp: +user.idApp,
      }
    };

    try {
      const res = await axiosWS.GetlastStores(data);
      if (res.status === 200) {
        const { data } = res;
        if (data.error === false) {
          lastStores.value = data.report; 
        }
      } else {
        throw new Error('Failed to fetch data for GetlastStores');
      }
    } catch (error) {
      console.error('Error fetching data for GetlastStores:', error.message);
    }
  }

  // Función para obtener las ventas más altas.
  async function  GetMostSalesMP() {
    // Prepara los datos para la solicitud.
    const data = {
      bodyOptions: {
        idShop: +user.idShop,
        idApp: +user.idApp,
      }
    };

    try {
      // Realiza la solicitud para obtener las ventas más altas.
      const res = await axiosWS.GetMostSalesMP(data);
      
      // Verifica si la solicitud fue exitosa (código de estado 200).
      if (res.status === 200) {
        const { data } = res;

        // Verifica si no hay errores en los datos recibidos.
        if (data.error === false) {
          // Actualiza la variable mostSales con el resultado de las ventas más altas.
          mostSales.value = data.report; 
        }
      } else {
        // Lanza un error si la solicitud no fue exitosa.
        throw new Error('Failed to fetch data for GetMostSalesMP');
      }
    } catch (error) {
      // Maneja cualquier error que pueda ocurrir durante la solicitud.
      console.error('Error fetching data for GetMostSalesMP:', error.message);
    }
  }


  async function getVisitorsApp() {
    try {
      const data = {
        bodyOptions: {
          idApp: +user.idApp,
        }
      };

      const getVisitorsAppRes = await axiosWS.getVisitorsApp(data);
      if (getVisitorsAppRes.status === 200) {
        const { data } = getVisitorsAppRes;
        console.log(data)
        if (data.error === false) {
          sales_donut_series.value = [data.visitors];
        } 
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }



  
</script>


<style>
.center-horizontal {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.TotalT{
  text-align: center;
  font-size: 2rem; 
  font-weight: 800; 
  color: #FF9400; 
}

.SubtitleT{
  text-align: start;
  font-size: 20px; 
  font-weight: 600; 
  color: #797E88;
}

.Subtitle2T{
  text-align: start;
  font-size: 22px; 
  font-weight: 800; 
  color: #797E88;
}
.flex-container {
  display: flex;
}

</style>
