<template>
  <div class="layout-px-spacing dash_1">
    <breadcrumb name="Data" />
    <!-- {{ store.getters }} -->
    <!-- {{store.getters.getUser?.appType}} -->
    <LoadingSpinner v-if="waiting === true" :key="waiting" />
    <div v-else-if="store.getters.getUser?.appType == 0" class="row layout-top-spacing">
      <h5 class="mt-4 mb-2">Reporte últimos 7 días </h5>
      
      <!-- Ventas Diarias Ya -->
      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing">
        
        <div class="widget widget-dailysales">

          <div class="widget-heading">
            <div>
              <h5>{{ $t('Daily sales') }}</h5>
              <span class="sub-title">{{ $t('Go to columns for details') }}</span>
            </div>
            <div class="w-icon text-warning">
              <svg
                xmlns="http://www.w3.org/2000/svg" width="24"             height="24"
                viewBox="0 0 24 24"                fill="none"            stroke="currentColor"
                stroke-width="2"                   stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-dollar-sign"
              >
                <line x1="12" y1="1" x2="12" y2="23"></line>
                <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
              </svg>
            </div>
          </div>

          <div class="widget-content">
            <apex-chart :key="createdDates" v-if="daily_sales_options" height="160" type="bar" :options="daily_sales_options" :series="daily_sales_series"></apex-chart>
          </div>

        </div>
      </div>
      <!-- Resumen Semanal -->
      <div :key="FinanceReport.income" class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing">
        <div class="widget widget-summary">
          <div class="widget-heading">
            <h5>{{ $t('Summary') }}</h5>
          </div>
          <div class="widget-content">
            <div class="summary-list">
              <div class="w-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-shopping-bag"
                >
                  <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                  <line x1="3" y1="6" x2="21" y2="6"></line>
                  <path d="M16 10a4 4 0 0 1-8 0"></path>
                </svg>
              </div>
              <div class="w-summary-details">
                <div class="w-summary-info">
                  <h6>{{ $t('Income') }}</h6>
                  <p class="summary-count">{{formatoSaldo.format(FinanceReport.income)}}</p>
                </div>
                <div class="w-summary-stats">
                  <div class="progress">
                    <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="90" class="progress-bar bg-gradient-secondary" style="width: 90%"></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="summary-list">
              <div class="w-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-credit-card"
                >
                  <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                  <line x1="1" y1="10" x2="23" y2="10"></line>
                </svg>
              </div>
              <div class="w-summary-details">
                <div class="w-summary-info">
                  <h6>{{ $t('Expenses') }}</h6>
                  <p class="summary-count">{{formatoSaldo.format(FinanceReport.expenses)}}</p>
                </div>
                <div class="w-summary-stats">
                  <div class="progress">
                    <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="80" class="progress-bar bg-gradient-warning" style="width: 80%"></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="summary-list">
              <div class="w-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-tag"
                >
                  <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path>
                  <line x1="7" y1="7" x2="7" y2="7"></line>
                </svg>
              </div>
              <div class="w-summary-details">
                <div class="w-summary-info">
                  <h6>{{ $t('Profit') }}</h6>
                  <p class="summary-count">{{formatoSaldo.format(FinanceReport.utility)}}</p>
                </div>
                <div class="w-summary-stats">
                  <div class="progress">
                    <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="65" class="progress-bar bg-gradient-success" style="width: 65%"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Transacciones -->
      <div :key="LastTrs?.length>0" class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-transaction">
          <div class="widget-heading">
            <h5>{{ $t('Transactions') }}</h5>
            <div class="task-action">
            </div>
          </div>

          <div :key="LastTrs?.length" v-if="LastTrs?.length>0" class="widget-content">
            <div v-for="client in LastTrs" :key="client.id" class="transactions-list">
              <div>
                <span class="b-avatar icon-fill-info me-2 badge-info rounded-circle">
                  <span class="b-avatar-text"><span>{{client.name.charAt(0)}} {{client.lastname.charAt(0)}} </span></span>
                </span>
                <div class="t-name">
                  <h4>{{client.name}} {{  }} {{client.lastname}}</h4>
                  <p>{{client.created_at}} </p>
                </div>
              </div>
              <div class="text-success">+ {{ formatoSaldo.format(client.total) }}</div>
            </div>  
          </div>
        </div>
      </div>

      
      <!-- Productos mas vendidos -->
      <div :key="LastMostSales?.length>0" class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-top-selling-products">
          <div class="widget-heading">
            <h5>{{ $t('Top Selling Product') }}</h5>
          </div>

          <div class="widget-content table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th><div class="th-content">Producto</div></th>
                  <th><div class="th-content th-heading">Precio de Venta</div></th>
                  <!-- <th><div class="th-content th-heading">Descuento</div></th> -->
                  <th><div class="th-content">Vendidos</div></th>
                  <!-- <th><div class="th-content">Fuente</div></th> -->
                </tr>
              </thead>
              <tbody :key="LastMostSales?.length" v-if="LastMostSales?.length>0" 
                style="
                  --bs-table-bg: transparent;
                " >
                <tr  v-for="product in LastMostSales" :key="product.id">
                  <td>
                    <div class="td-content d-flex">
                      <img :src="product.urlImg" alt="product" />
                      <div class="align-self-center">
                        <p class="prd-name">{{product.name}}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="td-content"><span class="discount-pricing">{{ (product.is_sale==1)? formatoSaldo.format(product.sale_price): formatoSaldo.format(product.price)}}</span></div>
                  </td>
                  <td>
                    <div class="td-content">{{ product.total_sales_amount }}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- Pedidos Recientes -->
      <div :key="LastTrReport?.length>0" class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-recent-orders">
          <div class="widget-heading">
            <h5>{{ $t('Recent Orders') }}</h5>
          </div>
          <div class="widget-content table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th><div class="th-content">Cliente</div></th>
                  <th><div class="th-content">Factura</div></th>
                  <th><div class="th-content th-heading">Precio</div></th>
                  <th><div class="th-content">Envio</div></th>
                  <th><div class="th-content">Estado</div></th>
                </tr>
              </thead>
              <tbody :key="LastTrReport?.length" v-if="LastTrReport?.length>0"
                style="
                  --bs-table-bg: transparent;
                " 
              >
                <tr  v-for="client in LastTrReport" :key="client.id">
                  <td>
                    <span> {{ client.fullname }}</span>
                  </td>
                  <td><div class="td-content"> {{ referencia(client.id) }}</div></td>
                  <td>
                    <div class="td-content"><span> {{ formatoSaldo.format(client.total) }}</span></div>
                  </td>
                  <td><div class="td-content text-primary">{{ formatoSaldo.format(client.shipping) }}</div></td>
                  <td>
                    <div class="td-content"><span class="badge badge-success">{{ client.description }}</span></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-sales-category">
          <div class="widget-heading">
            <h5>{{ $t('Visitors') }}</h5>
          </div>
          <div class="widget-content">
            <apex-chart v-if="sales_donut_options" height="460" type="donut" :options="sales_donut_options" :series="sales_donut_series"></apex-chart>
          </div>
        </div>
      </div>
      
    </div>
    <!-- MARKETPLACE -->
    <div v-else-if="store.getters.getUser?.appType == 1" class="layout-px-spacing cards-inicio">
      <indexMP/>

      <!-- <div v-scroll-spy class="row">
        <div v-for="(card, index) in cards" :key="index" class="col-lg-4 col-md-4 col-sm-6 layout-top-spacing">
          <div class="m-2 statbox box box-shadow">
            <div class="panel-body">
              <router-link :to="card.url" class="dropdown-toggle" @click="toggleMobileMenu">
                <div class="card component-card">
                  <div class="card-body">
                    <div class="icon-svg">
                      <center>
                        <img :src="card.graphic" alt="graphic" width="200" height="200" />
                      </center>
                    </div>
                    <h5 class="card-title" style="display: flex; justify-content: center; margin-left: auto; margin-right: auto;">{{ $t(card.title).toUpperCase() }}</h5>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
  <autorizationAdmin :key="autorization" :alertDisplay="autorization" />
  <logOut></logOut>
</template>

<script setup>
  // Importacion de librerias
  import { useStore } from 'vuex';
  import { useMeta } from '@/composables/use-meta';
  import { onMounted, computed, ref } from 'vue';
  import breadcrumb from '@/views/components/Maincomponents/breadCrumb.vue';

  // Importacion de Alerts y components
  import LoadingSpinner from '@/views/components/components_forms/loadingSpinner.vue';
  import ApexChart from 'vue3-apexcharts';
  import autorizationAdmin from '@/views/logOut/autorizationAdmin.vue';
  import { formatoSaldo } from '@/constants/constants';
  import logOut from '@/views/logOut/logOut.vue';
  import indexMP from '@/views/pages/index/indexMP.vue'
  // Librerias aparte de fontAwesome
  import '@/assets/sass/font-icons/fontawesome/css/fontawesome.css';
  import '@/assets/sass/font-icons/fontawesome/css/regular.css';
  // Librerias de Widgets
  import '@/assets/sass/widgets/widgets.scss';

  //Constantes del scrip
  const store            = useStore();
  const user             = store.getters.getUser;
  const localstore       = store.getters.getLocalStore;

  const createdDates     = ref(['Domingo' , 'Lunes'    , 'Martes'   , 'Miercoles', 'Jueves', 'Viernes', 'Sabado']);
  const FinanceReport    = ref({ income: 0, expenses: 0, utility: 0 })
  const waiting          = ref(false);
  const autorization     = ref(false);
  
  const LastTrReport     = ref([])
  const LastMostSales    = ref([])
  const LastTrs          = ref([])

  // Constantes del trabajo para data
  const daily_sales_series = ref([
    { name: 'NVentas', data: [0, 0, 0, 0, 0, 0, 0] },
    { name: 'Total'  , data: [0, 0, 0, 0, 0, 0, 0] },
  ]);

  const sales_donut_series = ref([0]);
  const sales_donut_options = computed(() => {
    const is_dark = store.state.is_dark_mode;
    const option = {
      chart: {},
      dataLabels: { enabled: false },
      expandOnClick: is_dark ? false : true,
      stroke: { show: true, width: 25, colors: is_dark ? '#0e1726' : '#fff' },
      colors: is_dark ? ['#5c1ac3', '#e2a03f', '#e7515a', '#e2a03f'] : ['#e2a03f', '#5c1ac3', '#e7515a'],
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        fontSize: '14px',
        markers: { width: 10, height: 10 },
        height: 50,
        offsetY: 20,
        itemMargin: { horizontal: 8, vertical: 0 },
      },
      plotOptions: {
        pie: {
          donut: {
            size: '65%',
            background: 'transparent',
            labels: {
              show: true,
              name: { show: true, fontSize: '29px', fontFamily: 'Nunito, sans-serif', offsetY: -10 },
              value: {
                show: true,
                fontSize: '26px',
                fontFamily: 'Nunito, sans-serif',
                color: is_dark ? '#bfc9d4' : undefined,
                offsetY: 16,
                formatter: function (val) {
                  return val;
                },
              },
              total: {
                show: true,
                label: 'Total',
                color: '#888ea8',
                fontSize: '29px',
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce(function (a, b) {
                    return a + b;
                  }, 0);
                },
              },
            },
          },
        },
      },
      labels: ['Visitantes'],
    };

    if (is_dark) {
      option['states'] = {
        hover: { filter: { type: 'none' } },
        active: { filter: { type: 'none' } },
      };
    }

    return option;
  });

  const daily_sales_options = computed(() => {
    return {
      chart: { toolbar: { show: false }, stacked: true, stackType: '100%' },
      dataLabels: { enabled: false },
      stroke: { show: true, width: 1 },
      colors: ['#e0e6ed', '#e2a03f'],
      responsive: [{ breakpoint: 480, options: { legend: { position: 'bottom', offsetX: -10, offsetY: 0 } } }],
      xaxis: { labels: { show: false }, categories: createdDates.value },
      yaxis: { show: false },
      fill: { opacity: 1 },
      plotOptions: { bar: { horizontal: false, columnWidth: '25%' } },
      legend: { show: false },
      grid: {
        show: false,
        xaxis: { lines: { show: false } },
        padding: { top: 10, right: -20, bottom: -20, left: -20 },
      },
    };
  });

  const cards = [
    { id: 1, title: 'Page/content',   url: '/Page-content',   graphic: require('@/assets/images/svg/online-ads-animate.svg') },
    // { id: 2, title: 'features',       url: '/Features',       graphic: require('@/assets/images/svg/FeaturesM.svg') },
    { id: 3, title: 'Shops',          url: '/Shops',          graphic: require('@/assets/images/svg/shops.svg') },
    { id: 4, title: 'Reports',        url: '/reports',        graphic: require('@/assets/images/svg/visual.svg') },
    // { id: 5, title: 'Configuration',  url: '/configuration',  graphic: require('@/assets/images/svg/Designer.svg') },
    { id: 6, title: 'Methods',        url: '/Payment',        graphic: require('@/assets/images/svg/Payments.svg') },
    { id: 7, title: 'Notifications',  url: '/Notifications',  graphic: require('@/assets/images/svg/notifications.svg') },
    // { id: 8, title: 'Reports',  url: '/reports',  graphic: require('@/assets/images/svg/visual.svg') },
    // { id: 9, title: 'Reports',  url: '/reports',  graphic: require('@/assets/images/svg/visual.svg') },



  ];

  if (localstore == 'es') {
    useMeta({ title: 'Datos' });
  }else{
    useMeta({ title: 'Data' });
  }
  onMounted(() => {
    if (user.idRol == 0 || user.idRol == 1) {
      autorization.value = true;
    }
    //ECO
    if(store.getters.getUser?.appType == 0) {
      // Ventas diarias ultimos 7 dias
      GetdailySalesSeries()
      //  Resumen 
      GetFinanceReport()
      // Ultimas Trs
      GetLastTrs()

      // Ultima Tr Report
      GetLastTrReport()
      // Mas vendidos
      GetLastMostSales()
      //Visitantes
      getVisitorsApp()
    }


  });

  // funciton to components
  function referencia(id){
    const paddedNumber = id.toString().padStart(6, '0');
    // console.log(paddedNumber);  // Output: "05"

    return "#"+paddedNumber;
  }

  //WS
  function GetdailySalesSeries() {
    const data = {
      bodyOptions: {
        idShop: +user.idShop,
        idApp: +user.idApp,
      }
    };

    // console.log(data)
    var GetDailySalesSeriesRes = axiosWS.GetDailySalesSeries(data);
    GetDailySalesSeriesRes.then((res) => {
      if (res.status == 200) {
        const { data } = res;
        if(data.error != true) {
          const sales = data.orders.map((order) => order.sales);
          var total = data.orders.map((order) => order.total);

          daily_sales_series.value = ([
            { name: 'NVentas', data: sales },
            { name: 'Total', data: total },
          ]);

          createdDates.value = data.orders.map((order) => order.created_at);

        }
      } 
    });
  
  }

  function GetFinanceReport() {
    const data = {
      bodyOptions: {
        idShop: +user.idShop,
        idApp: +user.idApp,
      }
    };

    // console.log(data)
    var GetFinanceReportRes = axiosWS.GetFinanceReport(data);

    GetFinanceReportRes.then((res) => {
      if (res.status == 200) {
        const { data } = res;
        // console.log(data)
        if(data.error == false) {
          FinanceReport.value.income   = data.report[0].income;
          FinanceReport.value.expenses = data.report[0].expenses;
          FinanceReport.value.utility  = data.report[0].utility;
        }
        // console.log(FinanceReport.value)

      } 
    });
  
  }

  function GetLastTrReport() {
    const data = {
      bodyOptions: {
        idShop: +user.idShop,
        idApp: +user.idApp,
      }
    };

    // console.log(data)
    var GetLastTrReportRes = axiosWS.GetLastTrReport(data);

    GetLastTrReportRes.then((res) => {
      if (res.status == 200) {
        const { data } = res;
        // console.log(data)
        if(data.error == false) {
          LastTrReport.value   = data.report
        }
      } 
    });
  
  }

  function GetLastMostSales() {
    const data = {
      bodyOptions: {
        idShop: +user.idShop,
        idApp: +user.idApp,
      }
    };

    // console.log(data)
    var GetLastMostSalesRes = axiosWS.GetLastMostSales(data);

    GetLastMostSalesRes.then((res) => {
      // console.log( "WS-LMS-->", res)

      if (res.status == 200) {
        const { data } = res;
        // console.log( "WS-LMS-->", data)
        if(data.error == false) {
          LastMostSales.value   = data.report
          // console.log("LMS--->", LastMostSales.value)
        }

      } 
    });
  
  }

  function GetLastTrs() {
    const data = {
      bodyOptions: {
        idShop: +user.idShop,
        idApp: +user.idApp,
      }
    };

    // console.log(data)
    var GetLastTrsRes = axiosWS.GetLastTrs(data);

    GetLastTrsRes.then((res) => {

      if (res.status == 200) {
        // console.log("ultimas trs--->", res.data)
        const { data } = res;
        if(data.error == false) {
          LastTrs.value   = data.report
        }

      } 
    });
  
  }

  async function getVisitorsApp() {
    try {
      const data = {
        bodyOptions: {
          idApp: +user.idApp,
        }
      };

      const getVisitorsAppRes = await axiosWS.getVisitorsApp(data);
      if (getVisitorsAppRes.status === 200) {
        const { data } = getVisitorsAppRes;
        
        if (data.error === false) {
          sales_donut_series.value = [data.visitors];
        } 
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }



  
</script>
