<template>
  <div :class="[$store.state.layout_style, $store.state.menu_style]">
    <component v-bind:is="layout"></component>
  </div>
</template>
<script setup>
  import { computed } from 'vue';

  import '@/assets/sass/app.scss';

  import { useMeta } from '@/composables/use-meta';
  import { useStore } from 'vuex';

  useMeta({ title: 'Admin' });

  const store = useStore();

  const layout = computed(() => {
    return store.getters.layout;
  });

  const user = store.getters.getUser;
  // console.log('lo que viendel storeuser', user);

  if (user.is_login == false) {

    var URLactual = window.location;
    // console.log(URLactual.pathname.substring(0,5))
    if(URLactual.pathname.substring(0,5)!='/lamp'){
      router.replace('/auth/login');
    }
    // console.log('Usuario logueado?:', user);
  }
</script>
<script>
  // layouts
  import appLayout from './layouts/app-layout.vue';
  import authLayout from './layouts/auth-layout.vue';

  export default {
    components: {
      app: appLayout,
      auth: authLayout,
    },
  };
</script>
