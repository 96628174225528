<template>
  <div class="navbar-item flex-row ms-md-auto">
    <div class="d-flex align-items-center">
      <a class="d-flex align-items-center">
        <div class="dropdown nav-item notification-dropdown btn-group">
          <a href="javascript:;" id="ddlnotify" data-bs-toggle="dropdown" aria-expanded="false" class="btn dropdown-toggle btn-icon-only nav-link">
            <span :key="shopActual?.nameShop" class="ms-2">
              {{ shopActual?.nameShop != null ? capitalize(shopActual?.nameShop) + ' - ' + capitalize(shopActual?.nameApp) : capitalize(shopActual?.nameApp) }}
            </span>
          </a>
          <ul :key="shopsUser" v-if="shopsUser?.length > 0" class="dropdown-menu dropdown-menu-right" aria-labelledby="ddlnotify">
            <div v-for="item in shopsUser" :key="item.id" @click="cambiarComercio(item.id, item)">
              <li role="presentation">
                <a class="dropdown-item">
                  <div class="media">
                    <div class="media-body">
                      <div class="data-info">
                        {{ (item?.idApp == 0 && item?.idShop == 0) ? "Contento - Super Admin" : item?.nameShop != null ? capitalize(process2Name(item.nameShop, item.nameApp)) : capitalize(processName(item.nameApp)) }}
                      </div>
                      <div class="icon-status">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-x"
                        >
                          <line x1="18" y1="6" x2="6" y2="18"></line>
                          <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <li role="presentation">
                <hr role="separator" aria-orientation="horizontal" size="0" class="dropdown-divider" />
              </li>
            </div>
          </ul>
        </div>
      </a>
    </div>

    <div class="dropdown nav-item message-dropdown btn-group"></div>
    <div class="dropdown nav-item message-dropdown btn-group"></div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';

let store = useStore();
let user = store.getters.getUser;
let shopsUser = store.getters.getshopsUser;
let shopActual = ref({
  nameApp: '',
  nameShop: '',
});
var appData = ref(store.getters.getApp);

onMounted(() => {
  setShopActual();
});

function setShopActual() {
  if (shopsUser?.length != 0) {
    if (user.idApp == 0 && user.idShop == 0 && user.idRol == 10) {
      shopActual.value.nameApp  = 'Super Admin';
      shopActual.value.nameShop = 'Contento';
    } else {
      var au = shopsUser.find((e) => e.idApp == user.idApp && e.idShop == user.idShop);

      if (au) {
        var nameApp = processName(au.nameApp);
        var nameShop = processName(au.nameShop);
        shopActual.value.nameApp = nameApp;
        shopActual.value.nameShop = nameShop;
        if(nameApp?.trim() == nameShop?.trim()) {
          shopActual.value.nameApp = ""
        }
      }
    }
  }
}

function processName(name) {
  // Convertir la cadena a minúsculas y eliminar '-principal'
  return name?.toLowerCase().replace('-principal', '');
}

function process2Name(shop,app) {
  // Convertir la cadena a minúsculas y eliminar '-principal'
  var shopO = shop?.toLowerCase().replace('-principal', '');
  var apppO = app?.toLowerCase().replace('-principal', '');

  if(shopO?.trim() == apppO?.trim()) {
    return shopO
  } else {
    return shopO + '-' + apppO
  }
}

function capitalize(str) {
  // Capitalizar la primera letra de cada palabra
  return str.replace(/\b\w/g, char => char.toUpperCase());
}

function cambiarComercio(id, shop) {
  if (shop.idShop != user.idShop) {
    var exp_date = new Date();

    const userSelectShop = {
      email: user.email,
      id: user.id,
      idRol: shop.idRol,
      idApp: shop.idApp,
      idShop: shop.idShop,
      name: shop.name,
      nameShop: shop.nameShop,
      is_login: true,
      appType: shop.appType,
      tokens: {
        access: user.tokens.access,
        refresh: user.tokens.refresh,
      },
      expiration: exp_date,
    };
    store.dispatch('setUser', userSelectShop);
    router.go(0);
  }
}
</script>

<style scoped>
#header-template {
  margin-left: 200px;
}
#first-letter {
  text-transform: capitalize;
}
</style>
