import { createApp }         from 'vue';
import App                   from './App.vue';
import router                from './router';
import store                 from './store';
import AxiosCalls            from './services/ws.ts';
import Swal                  from 'sweetalert2';
import vue3JsonExcel         from 'vue3-json-excel';             // json to excel
import VueFormWizard         from 'vue3-form-wizard';            // vue-wizard
import i18n                  from './i18n';                      // vue-i18n
import Maska                 from 'maska';                       // vue input mask
import { registerScrollSpy } from 'vue3-scroll-spy/dist/index';  // smooth scroll
import { ClientTable }       from 'v-tables-3';                  // datatables
import { createHead }        from '@vueuse/head';

import { library }           from '@fortawesome/fontawesome-svg-core';
import { fas }               from '@fortawesome/free-solid-svg-icons';
import { far }               from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon }   from '@fortawesome/vue-fontawesome';
import appSetting            from './app-setting'; // set default settings

import * as bootstrap        from 'bootstrap';
import PerfectScrollbar      from 'vue3-perfect-scrollbar';
import VueNouislider         from 'vue3-nouislider';

import 'vue3-nouislider/dist/vue3-nouislider.css';              // nouislider - later remove and add to page due to not working in page
import '@/assets/sass/components/custom-modal.scss';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import 'vue3-form-wizard/dist/style.css';

const app = createApp(App);

window.bootstrap = bootstrap;        // bootstrap
window.axios     = require('axios'); // Axios 
window.store     = store;            // Store FROM store
window.router    = router;           // Router global
window.axiosWS   = new AxiosCalls();
window.Swal      = Swal;             // Sweetalert
const head       = createHead();     // Vue-meta

registerScrollSpy(app, { offset: 118 });
window.$appSetting = appSetting;
window.$appSetting.init();
library.add(fas, far);

app
.use(store).use(router).use(i18n).use(PerfectScrollbar).use(VueNouislider).use(Maska).use(ClientTable).use(vue3JsonExcel).use(VueFormWizard).use(head)
.component('fa', FontAwesomeIcon)
.mount('#app');

